// Variable overrides

.c-sidebar-nav-dropdown-toggle,
.c-sidebar-nav-link,
.c-sidebar-nav-icon {
    color: rgba(44, 56, 74, 0.8) !important;
    font-size: 11pt;
    text-align: center;
}

.c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar-nav-link:hover {
    color: #fff !important;
    .c-sidebar-nav-icon {
        color: #fff !important;
    }
}

.c-sidebar-nav-dropdown-items > .c-sidebar-nav-item {
    background-color: #fff !important;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show {
    background-color: #fff !important;
}

.fc-daygrid {
    background-color: #fff !important;
}

.c-sidebar-minimized .c-sidebar-nav-link {
    padding-left: 0 !important;
}

.c-sidebar-minimized .c-sidebar-nav-icon {
    margin-left: 0 !important;
}

.nav-link-pill:not(.active) {
    border-radius: 100px !important;
    border: 1px solid #d8dbe0 !important;
    color: #3c4b64;
    background-color: #fff !important;
}

.nav-link-pill:not(.active):hover {
    background-color: #e9ebed !important;
}

.nav-link-pill.active {
    border-radius: 100px !important;
    background-color: #cfdef9 !important;
    border: 1px solid #cfdef9 !important;
    color: #321fdb !important;
}
